<div class="wrapper modification-{{ dish?.containerType | dishTransform }}" [class.is-tooltip-opened]="isTooltipOpened">
  <div class="inner">
    <div class="back-image state-{{ programType | lowercase}}"></div>
    <div class="menu-type text-12 text-center" *ngIf="dish?.mealType">{{ ruDishTypeEnum[dish?.mealType] }}</div>
    <picture class="img-container" *ngIf="isArray(dish.id)" (click)="open(dish)">
      <img
        class="img"
        [appImageNotFound]="'/assets/images/client-menu/empty-dish.png'"
        [src]="dish?.id[0] | dishImage : ImageTypeEnum.sitePreview : ImageFileTypeEnum.png"
      />
    </picture>
    <picture class="img-container" *ngIf="!isArray(dish.id)" (click)="open(dish)">
      <img
        class="img"
        [appImageNotFound]="'/assets/images/client-menu/empty-dish.png'"
        [src]="dish?.id | dishImage : ImageTypeEnum.sitePreview : ImageFileTypeEnum.png"
      />
    </picture>
    <div class="content text-12">
      <div class="text-info" [class.no-details]="dish.hasDuplicateDish">
        <div class="info-left" *ngIf="!dish.hasDuplicateDish">
          {{ dish.grams | customNumber: 'weight'}} г/ {{ dish.caloricity | customNumber: 'calories' }} ккал
        </div>
        <div class="info-right">
          <span class="info-detail" (click)="open(dish)">Состав</span>
        </div>
      </div>
      <div class="text-title text-14">{{ dish.title }}</div>

      <ng-container *ngIf="(dish?.priceIncrease || dish?.additionalPrice) as price">
        <div *ngIf="!dish.hasDuplicateDish" class="price">+{{ price }} <span class="ruble">₽</span></div>
      </ng-container>

      <div class="content-footer">
        <ng-container *ngIf="!dish?.hasDuplicateDish; else duplicate">
          <div class="text-btn text-14 mt-2" (click)="onSelectDish(dish)">Выбрать</div>
        </ng-container>

        <ng-template #duplicate>
          <div class="text-btn text-14 mt-2" (click)="openSelectPortionModal(dish)">Выбрать порцию</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
