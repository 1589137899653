<div
  [appTooltip]="[dish, package, isFromDetailsModal, isFromAdditionModal, subscriptionStatus] | f: getTooltipText"
  [isTooltipDisabled]="isTooltipDisabled()"
  [ngClass]="{'full-width': isFromDetailsModal}"
  class="tooltip-wrapper"
  delay="100"
>
  <!-- Single button that changes dynamically based on conditions -->
  <button
    *ngIf="[dish, package] | f: shouldShowButton"
    class="text-btn green-button"
    [ngClass]="isFromDetailsModal | f: getButtonClass"
    [disabled]="[dish, package, isFromDetailsModal, isFromAdditionModal, subscriptionStatus] | f: isButtonDisabled"
    (click)="handleButtonClick()"
  >
    {{ [dish, package, isFromDetailsModal, isFromAdditionModal] | f: getButtonText }}
  </button>
</div>

<ion-modal [isOpen]="isModalOpen" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <app-top-toolbar title="Замена для блюда" (goBack)="closeModal()"></app-top-toolbar>
    <app-dish-sorting-dropdown (choosefilter)="sortDishes($event)"></app-dish-sorting-dropdown>

    <ion-content class="ion-padding">
      <div class="old-dish">
        <div class="old-dish-info text-12">
          {{ dish.grams | customNumber: 'weight'}} г/ {{ dish.caloricity | customNumber: 'calories' }} ккал
        </div>
        <div class="old-dish-title text-14 text-semibold text-center">{{dish.title}}</div>
      </div>

      <div class="loading" *ngIf="isLoading$ | async; else contentBlock">
        <div class="loading-content">
          <p class="msg text--18">Загружаем блюда</p>
          <div class="dot-flashing"></div>
        </div>
      </div>

      <ng-template #contentBlock>
        <ng-container *ngIf="allDishes?.length; else noResultsBlock">
          <div class="meal-type-tabs pt-3 pb-2">
            <div
              class="item text-16 text-semibold"
              [ngClass]="{ active: activeMealTypeTab === category.key }"
              *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
              (click)="scrollToCategory(category.key)"
            >
              {{ RuDishTypesEnum[category.key] }}
            </div>
          </div>

          <div class="dishes-list" #dishesListRef>
            <div
              class="row dish-row"
              *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
              [ngClass]="category.key"
              [id]="category.key"
              appVisibilityObserver
              (visibilityChange)="setActiveCategory($event, category.key)"
            >
              <ng-container *ngTemplateOutlet="categoryTemplate; context: { title: category.key, dishes: category.value }"> </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #noResultsBlock>
          <div class="empty-list">
            <p class="msg text--18">Нет доступных блюд</p>
          </div>
        </ng-template>
      </ng-template>
    </ion-content>
  </ng-template>

  <ng-template #categoryTemplate let-title="title" let-dishes="dishes">
    <div class="my-3">
      <p class="text-18 text-semibold">{{ RuDishTypesEnum[title] }}</p>
    </div>

    <div class="list">
      <ng-container *ngFor="let dish of dishes">
        <app-dish-item-replaced
          [dish]="dish"
          [package]="package"
          (selectDish)="onSelectDish($event)"
          [isFromSelectDishModal]="true"
        ></app-dish-item-replaced>
      </ng-container>
    </div>
  </ng-template>
</ion-modal>
