<div class="modal-sorting">
  <app-icon icon="sort" class="sort-icon" (click)="showSortDropdown()"></app-icon>
  <div class="dropdown header-dropdown-menu" [class.shown]="isSortingDropdownShown$ | async">
    <p class="dropdown-title text-18 text-semibold">Как сортировать блюда?</p>
    <div class="dropdown-options">
      <app-radio-toggler
        class="save-card-toggle"
        [value]="filterMethod === FilterMethod.ByCcal"
        (changeValue)="selectFilterMethod(FilterMethod.ByCcal)"
        >По разнице ккал</app-radio-toggler
      >
      <app-radio-toggler
        class="save-card-toggle"
        [value]="filterMethod === FilterMethod.ByTitle"
        (changeValue)="selectFilterMethod(FilterMethod.ByTitle)"
        >По алфавиту</app-radio-toggler
      >
      <app-radio-toggler
        class="save-card-toggle"
        [value]="filterMethod === FilterMethod.ByPrice"
        (changeValue)="selectFilterMethod(FilterMethod.ByPrice)"
        >По цене</app-radio-toggler
      >
    </div>

    <button class="btn sort-btn" (click)="sortDishes()">Показать</button>
  </div>
</div>
