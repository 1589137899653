import { ChangeDetectionStrategy, Component, Input, OnInit, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { RuDishTypesEnum, SubscriptionStatusEnum } from '@shared/enums';
import { ClientSubscriptionPackage, MenuItem } from '@shared/models';
import { NgOnDestroyService } from '@shared/services';
import { DishImagePipe } from '@shared/pipes';
import { ClientMenuDish } from '@modules/client-menu/models';
import { FeedbackDishService } from '@modules/client-menu/services';
import { DeleteCustomizationById } from '@store/client-menu';

@Component({
  selector: 'app-dish-details',
  templateUrl: './dish-details.component.html',
  styleUrls: ['./dish-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService, DishImagePipe],
})
export class DishDetailsComponent implements OnInit {
  @Input() dish: ClientMenuDish;
  @Input() menuExampleDish: MenuItem;
  @Input() isFromMenuExample: boolean;
  @Input() package: ClientSubscriptionPackage;
  @Input() isFromAdditionModal: boolean;
  @Input() isFromSelectDishModal: boolean = false;
  @Input() subscriptionStatus = SubscriptionStatusEnum.Active;

  public readonly ruDishTypeEnum = RuDishTypesEnum;

  public content: string;
  public menuExampleContent: string;

  constructor(
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private store: Store,
    private modalCtrl: ModalController,
    private feedbackDishService: FeedbackDishService,
  ) {}

  ngOnInit(): void {
    this.content = (this.dish?.ingredients || '').split(',').join(', ');
    this.menuExampleContent = (this.menuExampleDish?.ingredients || '').split(',').join(', ');
  }

  public closeModal(): void {
    this.modalCtrl.dismiss();
  }

  public setDishRating(dish: ClientMenuDish, userPackage: ClientSubscriptionPackage): void {
    this.feedbackDishService
      .openDialog({
        dish,
        packageId: userPackage.packageId,
        date: userPackage.date,
      })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe();
  }

  public deselect(dish: ClientMenuDish): void {
    this.store.dispatch(new DeleteCustomizationById(this.package.packageId, dish.customizationId));
    this.closeModal();
  }

  public isArray(property: number | string | string[] | number[]): boolean {
    return Array.isArray(property);
  }
}
