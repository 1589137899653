import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { State, Action, StateContext, NgxsOnInit, Store, ofActionDispatched, Actions } from '@ngxs/store';

import { SetAuth } from '@store/auth';
import { UpdateProgramPrice } from '@store/program';
import { ProfileApiService, SentryService } from '@shared/services';
import { GendersEnum } from '@shared/enums';
import { ProfileData } from '@shared/models';
import { SetGender, SetProfile, LoadProfile, SetCutlery } from './profile.actions';
import { ProfileStateModel } from './profile.model';

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    profile: null,
    gender: GendersEnum.female,
  },
})
@Injectable()
export class ProfileState implements NgxsOnInit {
  constructor(
    private store: Store,
    private actions$: Actions,
    private profileApiService: ProfileApiService,
    private sentryService: SentryService,
  ) {}

  ngxsOnInit(): void {
    this.actions$.pipe(ofActionDispatched(SetAuth)).subscribe(({ isAuthorized }: SetAuth) => {
      this.initProfile(isAuthorized);
    });
  }

  @Action(SetProfile)
  setProfile(ctx: StateContext<ProfileStateModel>, { profile }: SetProfile): void {
    ctx.patchState({
      profile,
    });
  }

  @Action(SetGender)
  setGender(ctx: StateContext<ProfileStateModel>, { gender }: SetGender): void {
    ctx.patchState({
      gender,
    });
  }

  private initProfile(isAuthorized: boolean): void {
    if (!isAuthorized) {
      this.store.dispatch(new SetProfile(null));
      return;
    }

    this.store.dispatch(new LoadProfile());
  }

  @Action(LoadProfile)
  loadProfile(): void {
    this.profileApiService.getProfile().subscribe((profile: ProfileData) => {
      this.store.dispatch(new SetProfile(profile));

      this.sentryService.setUser(profile);
    });
  }

  @Action(SetCutlery)
  setCutlery({ getState, patchState, dispatch }: StateContext<ProfileStateModel>, { cutleryRequired }: SetCutlery): Observable<void> {
    const { profile } = getState();

    if (profile?.defaultDeliverySettings?.cutleryRequired === cutleryRequired) {
      return;
    }

    const updatedProfile: ProfileData = {
      ...profile,
      defaultDeliverySettings: {
        ...profile.defaultDeliverySettings,
        cutleryRequired,
      },
    };

    return this.profileApiService.updateProfile(updatedProfile).pipe(
      tap(() => {
        patchState({ profile: updatedProfile });

        dispatch(new UpdateProgramPrice());
      }),
    );
  }
}
