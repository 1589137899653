import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { tap, catchError, delay } from 'rxjs/operators';
import { Action, State, StateContext } from '@ngxs/store';

import { SetLoading, UnsetLoading } from '@store/loading';
import { ProgramBlockEventsEnum } from '@modules/client-menu/enums';
import { AvailableMovingDays, CurrentProgram, SubscriptionInfo, SubscriptionPriceResponse } from '@shared/models';
import { LoadingTagEnum, LocalStorageKeysEnum, ProgramCaloriesEnum, ProgramDurationsEnum, ProgramTypesEnum } from '@shared/enums';
import { RudderStackService, SubscriptionService } from '@shared/services';
import { CommonHelper, formatToDuration } from '@shared/utils';
import {
  SetProgram,
  SetPromocode,
  SetPromocodeEnablementStatus,
  SetPromocodeErrorMessage,
  SetStartDeliveryDay,
  UpdateProgramPrice,
  UpdateDeliveryIntervalInfo,
  InitProgram,
  LoadAvailableDays,
  RefreshProgramData,
  GetCalendarAvailableMovingDays,
  SetProgramDiscount,
  SetIsFamily,
  SetIsHideFamily,
  LoadSubscriptionById,
  RenewSubscriptionById,
} from './program.actions';
import { ProgramStateModel } from './program.model';
import { ProgramHelpers } from './program.helpers';

const initialState: ProgramStateModel = {
  program: {
    dailyCalories: ProgramCaloriesEnum.cal1200,
    durationId: ProgramDurationsEnum.twoWeeks,
    durationInDays: 12,
    isSplittable: false,
    applyBonuses: false,
    extOptions: {
      eatOnSaturdayAndSunday: false,
    },
  },
  isFamily: false,
  isHideFamily: false,
  promocodeFromInput: '',
  promocodeFromUrl: '',
  promocodeErrorMessage: '',
  isPromocodeEnabled: true,
  startDeliveryDay: null,
  availableDates: [],
  calendarAvailableMovingDays: null,
  isLoadingMovingDays: false,
  deliveryIntervalInfo: null,
  priceData: null,
  availableDiscounts: [],
  subscriptionInfo: null,
};

@State<ProgramStateModel>({
  name: 'program',
  defaults: initialState,
})
@Injectable()
export class ProgramState {
  constructor(
    private programHelpers: ProgramHelpers,
    private commonhelper: CommonHelper,
    private subscriptionService: SubscriptionService,
    private rudderstack: RudderStackService,
  ) {}

  @Action(InitProgram)
  async initProgram({ dispatch, patchState, getState }: StateContext<ProgramStateModel>, { data }: InitProgram) {
    const { program } = getState();

    const initialProgram = await this.programHelpers.getInitialProgram();

    // Проверяем находимся ли мы в процессе создания семейной подписки
    const isFamily = await Preferences.get({ key: LocalStorageKeysEnum.isFamily });
    const isHideFamily = await Preferences.get({ key: LocalStorageKeysEnum.isHideFamily });

    patchState({ isHideFamily: Boolean(isHideFamily.value === 'true'), isFamily: Boolean(isFamily.value === 'true') });

    if (Boolean(isHideFamily.value)) {
      Preferences.set({ key: LocalStorageKeysEnum.isFamily, value: 'false' });
      Preferences.set({ key: LocalStorageKeysEnum.isHideFamily, value: 'false' });
    }
    // ------

    const value = data ? { ...program, ...data } : initialProgram;

    dispatch(new SetProgram(value));
  }

  @Action(SetProgram)
  setProgram({ getState, patchState, dispatch }: StateContext<ProgramStateModel>, { data }: SetProgram) {
    dispatch(new SetLoading(LoadingTagEnum.updatingProgram));

    this.rudderstack.track(ProgramBlockEventsEnum.programParamsChanged, { ...data });

    const { program } = getState();
    const updatedProgram = this.programHelpers.getUpdatedProgram(program, data);

    patchState({ program: updatedProgram });

    dispatch(new UnsetLoading(LoadingTagEnum.updatingProgram));

    Preferences.set({
      key: LocalStorageKeysEnum.currentProgram,
      value: JSON.stringify(updatedProgram),
    });

    dispatch(new UpdateProgramPrice());
  }

  @Action(UpdateProgramPrice)
  updateProgramPrice({ getState, patchState, dispatch }: StateContext<ProgramStateModel>, { promocode }: UpdateProgramPrice) {
    const { program, promocodeFromInput, promocodeFromUrl } = getState();

    if (!program.durationId) {
      return EMPTY;
    }

    const activePromocode = promocode || promocodeFromInput || promocodeFromUrl || '';

    dispatch(new SetLoading(LoadingTagEnum.updatingProgramPrice));

    patchState({
      isPromocodeEnabled: true,
      promocodeErrorMessage: '',
    });

    return this.programHelpers.getPrice(program, activePromocode, patchState).pipe(
      tap((priceData: SubscriptionPriceResponse) => {
        patchState({ priceData });

        dispatch(new UnsetLoading(LoadingTagEnum.updatingProgramPrice));
      }),
    );
  }

  @Action(SetProgramDiscount)
  setProgramDiscount({ getState, patchState }: StateContext<ProgramStateModel>, { durationOptions }: SetProgramDiscount) {
    const { program, promocodeFromInput, promocodeFromUrl, startDeliveryDay } = getState();

    if (program.isSplittable) {
      return EMPTY;
    }

    const availableDiscountParams = this.programHelpers.getAvailableDiscountParams(
      program,
      startDeliveryDay,
      durationOptions,
      promocodeFromInput,
      promocodeFromUrl,
    );

    return this.subscriptionService
      .getAvailableDiscount(availableDiscountParams)
      .pipe(tap(availableDiscounts => patchState({ availableDiscounts })));
  }

  @Action(SetPromocode)
  setPromocode({ patchState }: StateContext<ProgramStateModel>, { promocode, type }: SetPromocode): void {
    switch (type) {
      case 'fromInput':
        patchState({ promocodeFromInput: promocode });
        break;
      case 'fromUrl':
        patchState({ promocodeFromUrl: promocode });
        break;
    }
  }

  @Action(SetPromocodeEnablementStatus)
  setPromocodeEnablementStatus(
    { patchState }: StateContext<ProgramStateModel>,
    { isPromocodeEnabled }: SetPromocodeEnablementStatus,
  ): void {
    patchState({
      isPromocodeEnabled,
    });
  }

  @Action(SetPromocodeErrorMessage)
  setPromocodeErrorMessage({ patchState }: StateContext<ProgramStateModel>, data: SetPromocodeErrorMessage): void {
    patchState({
      promocodeErrorMessage: data.errorMessage,
    });
  }

  @Action(LoadAvailableDays)
  loadAvailableDays({ getState, patchState }: StateContext<ProgramStateModel>, { isAuthorized }) {
    if (!isAuthorized) {
      return EMPTY;
    }

    const { program, isHideFamily } = getState();
    const days = program.durationInDays;
    const args = [days, program.dailyCalories] as const;
    const requests: Observable<string | string[]>[] = [];

    const availableDaysRequest$ = this.subscriptionService.getAvailableStartDaysOnMonth(...args).pipe(
      tap(availableDates => {
        patchState({ availableDates });
      }),
    );

    requests.push(availableDaysRequest$);

    if (!isHideFamily) {
      const startDeliveryDayRequest$ = this.subscriptionService.getAvailableStartDateAfterLastPackage(...args).pipe(
        tap(startDeliveryDay => {
          patchState({ startDeliveryDay });
        }),
      );

      requests.push(startDeliveryDayRequest$);
    }

    return combineLatest([...requests]);
  }

  @Action(SetStartDeliveryDay)
  setStartDeliveryDate({ patchState }: StateContext<ProgramStateModel>, { startDeliveryDay }: SetStartDeliveryDay): void {
    patchState({
      startDeliveryDay,
    });
  }

  @Action(UpdateDeliveryIntervalInfo)
  updateDeliveryIntervalInfo({ getState, patchState }: StateContext<ProgramStateModel>) {
    const { program } = getState();

    if (!program) {
      return null;
    }

    return this.subscriptionService.getDeliveryInterval().pipe(
      tap(deliveryIntervalInfo => {
        patchState({ deliveryIntervalInfo });
      }),
    );
  }

  @Action(GetCalendarAvailableMovingDays)
  getCalendarAvailableMovingDays(
    { patchState }: StateContext<ProgramStateModel>,
    { dayItem, subscriptionId }: GetCalendarAvailableMovingDays,
  ): Observable<AvailableMovingDays> {
    patchState({
      calendarAvailableMovingDays: null,
      isLoadingMovingDays: true,
    });

    const dayToMove = dayItem.date.format('YYYY-MM-DD');

    return this.subscriptionService.fetchAvailableMovingDays(dayToMove, subscriptionId).pipe(
      tap(calendarAvailableMovingDays => {
        patchState({
          calendarAvailableMovingDays,
          isLoadingMovingDays: false,
        });
      }),
      catchError(() => {
        patchState({ isLoadingMovingDays: false });
        return EMPTY;
      }),
    );
  }

  @Action(RefreshProgramData)
  refreshProgramData({ patchState }: StateContext<ProgramStateModel>): void {
    patchState(initialState);
  }

  @Action(SetIsFamily)
  setIsFamily({ patchState }: StateContext<ProgramStateModel>, { isFamily }: SetIsFamily): void {
    Preferences.set({ key: LocalStorageKeysEnum.isFamily, value: JSON.stringify(isFamily) });
    patchState({ isFamily });
  }

  @Action(SetIsHideFamily)
  setIsHideFamily({ patchState }: StateContext<ProgramStateModel>, { isHideFamily }: SetIsHideFamily): void {
    Preferences.set({ key: LocalStorageKeysEnum.isHideFamily, value: JSON.stringify(isHideFamily) });
    patchState({ isHideFamily });
  }

  @Action(LoadSubscriptionById)
  loadSubscriptionById(
    { patchState, dispatch }: StateContext<ProgramStateModel>,
    { subscriptionId }: LoadSubscriptionById,
  ): Observable<SubscriptionInfo> {
    dispatch(new SetLoading(LoadingTagEnum.loadSubscriptionInfo));

    return this.subscriptionService.fetchSubscriptionInfoById(subscriptionId).pipe(
      tap((info: SubscriptionInfo) => {
        const subscriptionInfo = this.commonhelper.mapSubscriptionInfo(info);
        patchState({ subscriptionInfo, startDeliveryDay: info.startDate });
      }),
      delay(100),
      tap(() => dispatch(new UnsetLoading(LoadingTagEnum.loadSubscriptionInfo))),
    );
  }

  @Action(RenewSubscriptionById)
  renewSubscriptionById(
    { dispatch }: StateContext<ProgramStateModel>,
    { subscriptionId }: LoadSubscriptionById,
  ): Observable<SubscriptionInfo> {
    if (!subscriptionId) {
      return;
    }

    dispatch(new SetLoading(LoadingTagEnum.loadSubscriptionInfo));

    return this.subscriptionService.fetchSubscriptionInfoById(subscriptionId).pipe(
      tap(({ size, daysCount }: SubscriptionInfo) => {
        const partialProgram: Partial<CurrentProgram> = {
          dailyCalories: size,
          durationId: formatToDuration(daysCount),
          durationInDays: daysCount,
        };

        dispatch(new SetProgram(partialProgram));
      }),
      tap(() => dispatch(new UnsetLoading(LoadingTagEnum.loadSubscriptionInfo))),
    );
  }
}
