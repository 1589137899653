<app-modal-layout [showBack]="true" [title]="title" (onGoBack)="closeModal()" (onClose)="closeModal()">
  <div class="wrapper">
    <div class="dialog-header">
      <app-dish-sorting-dropdown (choosefilter)="sortDishes($event)"></app-dish-sorting-dropdown>
    </div>

    <div class="loading" *ngIf="isLoading$ | async; else contentBlock">
      <div class="loading-content">
        <p class="text-18 text-center">Загружаем блюда</p>
        <div class="dot-flashing"></div>
      </div>
    </div>

    <ng-template #contentBlock>
      <ng-container *ngIf="dishesList?.length; else noResultsBlock">
        <div class="meal-type-tabs pt-5 pb-1">
          <div
            class="item text-16 text-semibold pb-1"
            [ngClass]="{ active: activeMealTypeTab === category.key }"
            *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
            (click)="scrollToCategory(category.key)"
          >
            {{ RuDishTypesEnum[category.key] }}
          </div>
        </div>

        <div class="dishes-list" #dishesListRef>
          <div
            class="row dish-row"
            *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
            [ngClass]="category.key"
            [id]="category.key"
            appVisibilityObserver
            (visibilityChange)="setActiveCategory($event, category.key)"
          >
            <ng-container *ngTemplateOutlet="categoryTemplate; context: { title: category.key, dishes: category.value }"> </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #noResultsBlock>
        <div class="empty-list">
          <p class="text-18 text-center">Нет доступных блюд</p>
        </div>
      </ng-template>
    </ng-template>
  </div>

  <ng-template #categoryTemplate let-title="title" let-dishes="dishes">
    <div class="my-3">
      <p class="text-18 text-semibold">{{ RuDishTypesEnum[title] }}</p>
    </div>

    <div class="list">
      <ng-container *ngFor="let dish of dishes">
        <app-dish-item-replaced
          [dish]="dish"
          [package]="package"
          (selectDish)="select(dish)"
          [isFromAdditionModal]="true"
        ></app-dish-item-replaced>
      </ng-container>
    </div>
  </ng-template>

  <button *ngIf="isShowSkipButton" class="btn skip-btn" (click)="closeModal()">Пропустить</button>
</app-modal-layout>
