import { ChangeDetectionStrategy, Input, Component, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { NgOnDestroyService } from '@shared/services';
import { RuDishTypesEnum, ImageTypeEnum, ImageFileTypeEnum, ProgramTypesEnum } from '@shared/enums';
import { ClientSubscriptionPackage } from '@shared/models';
import { DishImagePipe } from '@shared/pipes';

import { ClientMenuDish } from '@modules/client-menu/models';
import { DishDetailsComponent } from '../dish-details';
import { ChooseDishPortionComponent } from '../choose-dish-portion';
@Component({
  selector: 'app-dish-item-replaced',
  templateUrl: './dish-item-replaced.component.html',
  styleUrls: ['./dish-item-replaced.component.scss'],
  providers: [NgOnDestroyService, DishImagePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishItemReplacedComponent {
  @Input() dish: ClientMenuDish;
  @Input() programType: ProgramTypesEnum;
  @Input() scrollableContainer: HTMLElement;
  @Input() package: ClientSubscriptionPackage;
  @Input() isFromAdditionModal: boolean;
  @Input() isFromSelectDishModal: boolean;

  @Output() selectDish = new EventEmitter<ClientMenuDish>();

  readonly ruDishTypeEnum = RuDishTypesEnum;
  readonly ImageTypeEnum = ImageTypeEnum;
  readonly ImageFileTypeEnum = ImageFileTypeEnum;
  isTooltipOpened = false;

  constructor(private dishImagePipe: DishImagePipe, private modalCtrl: ModalController) {}

  getImageSources(dishCode: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    if (!dishCode) {
      return null;
    }

    return this.dishImagePipe.transform(dishCode, imageType, fileType);
  }

  onSelectDish(dish: ClientMenuDish): void {
    this.selectDish.emit(dish);
  }

  public isArray(property: any): boolean {
    return Array.isArray(property);
  }

  async open(dish: ClientMenuDish): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DishDetailsComponent,
      cssClass: 'dish-details-modal',
      mode: 'md',
      breakpoints: [0, 0.25, 0.5, 1],
      initialBreakpoint: 1,
      componentProps: {
        dish,
        package: this.package,
        isFromAdditionModal: this.isFromAdditionModal,
        isFromSelectDishModal: this.isFromSelectDishModal,
      },
    });

    modal.present();
    const result = await modal.onDidDismiss();
    const addedDish: ClientMenuDish = result.data;
    if (addedDish) {
      this.selectDish.emit(addedDish);
    }
  }

  async openSelectPortionModal(dish: ClientMenuDish): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChooseDishPortionComponent,
      cssClass: dish.id?.length > 2 ? 'choose-portion-modal-large' : 'choose-portion-modal',
      mode: 'md',
      breakpoints: [0, 0.25, 0.5, 1],
      initialBreakpoint: 1,
      componentProps: {
        dish,
      },
    });
    modal.present();

    const result = await modal.onDidDismiss();

    if (result.data) {
      this.selectDish.emit(result.data);
    }
  }
}
