<div
  [class]="'item item-day px-1 pe-' + size"
  [ngClass]="{
  'isCustomized': isCustomized,
  'first': defineFirstDate(data),
  'current': data.packageId === activePackageId,
  'is-feed-date': data.isFeedDate,
  'not-feed-date': !data.isFeedDate,
  'is-delivery-date': data.isDeliveryDate && data.isFeedDate,
  'delivered': data?.packageState === 'Delivered'
}"
  (click)="onSelect()"
>
  <div class="text text-12">
    <span>{{ data?.displayDayWeek }}</span>
    <div class="data-number" [class.text-semibold]="data.isDeliveryDate && data.isFeedDate">{{ data?.displayDate }}</div>
  </div>
</div>
