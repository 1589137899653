import { SubscriptionSelectorComponent } from './subscription-selector';
import { CalendarSelectorComponent } from './calendar-selector';
import { CalendarItemComponent } from './calendar-item';
import { DishesListComponent } from './dishes-list';
import { DishItemComponent } from './dish-item';
import { DishItemReplacedComponent } from './dish-item-replaced';
import { DishListHeaderComponent } from './dish-list-header';
import { SelectDishModalComponent } from './select-dish-modal';
import { ConfirmationModalComponent } from './confirmation-modal';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { DishRatingComponent } from './dish-rating/dish-rating.component';
import { SelectVariantComponent } from './select-variant';
import { DishDetailsComponent } from './dish-details';
import { DishAdditionDialogComponent } from './dish-addition-dialog';
import { ChooseDishPortionComponent } from './choose-dish-portion';
import { DishParamsComponent } from './dish-params';
import { DishSortingDropdown } from './dish-sorting-dropdown';

export const components = [
  SubscriptionSelectorComponent,
  CalendarSelectorComponent,
  CalendarItemComponent,
  DishesListComponent,
  DishItemComponent,
  DishItemReplacedComponent,
  DishListHeaderComponent,
  DishParamsComponent,
  SelectDishModalComponent,
  ConfirmationModalComponent,
  CancelModalComponent,
  DishRatingComponent,
  SelectVariantComponent,
  DishDetailsComponent,
  ChooseDishPortionComponent,
  DishAdditionDialogComponent,
  DishSortingDropdown,
];

export {
  SubscriptionSelectorComponent,
  CalendarSelectorComponent,
  CalendarItemComponent,
  DishesListComponent,
  DishItemComponent,
  DishItemReplacedComponent,
  DishListHeaderComponent,
  SelectDishModalComponent,
  ConfirmationModalComponent,
  CancelModalComponent,
  DishRatingComponent,
  SelectVariantComponent,
  DishDetailsComponent,
  ChooseDishPortionComponent,
  DishAdditionDialogComponent,
  DishSortingDropdown,
};
