import { ChangeDetectorRef, ChangeDetectionStrategy, Component, ElementRef, OnInit, Self, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, finalize, takeUntil } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';

import { trackByFn } from '@modules/client-menu/containers/client-menu-page/client-menu-page.helpers';
import { groupDishesWithSameTitles } from '@modules/client-menu/helpers';
import { ClientMenuDish } from '@modules/client-menu/models';
import { ClientMenuApiService } from '@modules/client-menu/services';
import { sortByMealType } from '@modules/client-menu/utils/sort-by-meal-type.util';
import { DishTypesNumericEnum, FilterMethod, LocalStorageKeysEnum, RuDishTypesEnum } from '@shared/enums';
import { NgOnDestroyService } from '@shared/services';
import { ClientSubscriptionPackage } from '@shared/models';

interface DishCategories {
  [key: string]: ClientMenuDish[];
}

@Component({
  selector: 'app-dish-addition-dialog',
  templateUrl: './dish-addition-dialog.component.html',
  styleUrls: ['./dish-addition-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class DishAdditionDialogComponent implements OnInit {
  @Input() package: ClientSubscriptionPackage;
  @Input() title: string;
  @Input() isShowSkipButton = false;
  @Input() isChildrenView: boolean;
  @Input() shouldUseIsRetail = false;

  @ViewChild('dishesListRef') private dishesListRef: ElementRef;

  public readonly RuDishTypesEnum = RuDishTypesEnum;
  public readonly trackByFn = trackByFn;
  public readonly sortByMealTypeFn = sortByMealType;

  public dishesList: ClientMenuDish[] = [];
  public dishesCategories: DishCategories;
  public scrollableContainer: HTMLElement;
  public isWinOS = false;
  public isLoading$ = new BehaviorSubject<boolean>(true);
  public areIngredientFiltering$ = new BehaviorSubject<boolean>(false);
  public activeMealTypeTab: string = 'Brunch';

  constructor(
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private clientMenuApiService: ClientMenuApiService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit(): void {
    this.fetchDishesList(this.package.packageId);
  }

  public async select(dish: ClientMenuDish): Promise<void> {
    if (this.isArray(dish.id)) {
      const selectedDishId = await Preferences.get({ key: LocalStorageKeysEnum.selectedDishForPortion });
      const dishesToReplace = await Preferences.get({ key: LocalStorageKeysEnum.replacementDishes });
      const dish = JSON.parse(dishesToReplace.value).filter(d => d.id === JSON.parse(selectedDishId.value))[0];
      this.modalCtrl.dismiss(dish);
    }
    this.modalCtrl.dismiss(dish);
  }

  public closeDialog(): void {
    this.modalCtrl.dismiss();
  }

  private fetchDishesList(packageId: string): void {
    if (!packageId) {
      return;
    }

    this.isLoading$.next(true);
    this.clientMenuApiService
      .getAdditionalDishes(packageId)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.isLoading$.next(false);
        }),
        filter(Boolean),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(async dishesList => {
        const list = this.shouldUseIsRetail ? dishesList.filter(({ isRetail }) => isRetail) : dishesList;
        const mergedGroupedArray = groupDishesWithSameTitles(list);
        const childrenDishes = groupDishesWithSameTitles(dishesList.filter(dish => dish.allowForChildren));
        this.dishesList = this.isChildrenView ? childrenDishes : mergedGroupedArray;

        await Preferences.set({
          key: LocalStorageKeysEnum.replacementDishes,
          value: JSON.stringify(dishesList),
        });

        const filteredDishes = this.isChildrenView ? childrenDishes : mergedGroupedArray;

        this.dishesCategories = this.filterDishesCategories(filteredDishes);

        this.cdr.markForCheck();

        setTimeout(() => {
          this.scrollableContainer = this.dishesListRef?.nativeElement;
        });
      });
  }

  public filterByCcal() {
    this.dishesList = this.dishesList.sort((a, b) => a.caloricity - b.caloricity);
  }

  public filterByDishTitle() {
    this.dishesList = this.dishesList.sort((a, b) => a.title.localeCompare(b.title));
  }

  public filterByPrice() {
    this.dishesList = this.dishesList.sort((a, b) => a.additionalPrice - b.additionalPrice);
  }

  public sortDishes(filterMethod: FilterMethod) {
    if (filterMethod === FilterMethod.ByCcal) {
      this.filterByCcal();
    }
    if (filterMethod === FilterMethod.ByTitle) {
      this.filterByDishTitle();
    }
    if (filterMethod === FilterMethod.ByPrice) {
      this.filterByPrice();
    }

    const mergedGroupedArray = groupDishesWithSameTitles(this.dishesList);
    const filteredDishes = groupDishesWithSameTitles(mergedGroupedArray);
    this.dishesCategories = this.filterDishesCategories(filteredDishes);

    this.cdr.markForCheck();
  }

  public scrollToCategory(categoryName: string): void {
    this.activeMealTypeTab = categoryName;
    let element = document.getElementById(categoryName);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  public setActiveCategory(isCategoryVisible: boolean, categoryName: string): void {
    if (!isCategoryVisible) {
      return;
    }

    this.activeMealTypeTab = categoryName;
  }

  private filterDishesCategories(filteredDishes: ClientMenuDish[]): DishCategories {
    this.dishesCategories = this.getDishesCategories(filteredDishes);
    const sortedCategories = Object.keys(this.dishesCategories)
      .sort((a, b) => DishTypesNumericEnum[a] - DishTypesNumericEnum[b])
      .reduce((acc, key) => {
        acc[key] = this.dishesCategories[key];
        return acc;
      }, {});

    return sortedCategories;
  }

  private getDishesCategories(dishesList: ClientMenuDish[]): DishCategories {
    return dishesList.reduce((prev, curr) => {
      const mealType = curr.mealType;

      if (!prev[mealType]) {
        prev[mealType] = [curr];
        return prev;
      }

      prev[mealType] = [...prev[mealType], curr];
      return prev;
    }, {});
  }

  public isArray(property: any): boolean {
    return Array.isArray(property);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
