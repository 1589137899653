import { ClientMenuDish } from '@modules/client-menu/models';
import { ClientSubscription, ClientSubscriptionDetails, ClientSubscriptionPackage, DayItem, Notification } from '@shared/models';

export interface ClientMenuStateModel {
  activeSubscriptionDetails: ClientSubscriptionDetails;
  subscriptionList: ClientSubscription[];
  activePackage: ClientSubscriptionPackage;
  replacementDishes: ClientMenuDish[];
  notifications: Notification[];
}

export interface ClientMenuDishesMap {
  [dishUuid: string]: ClientMenuDish;
}
