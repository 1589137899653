<div class="ion-activatable wrapper subscription-item-wrapper state-{{ data.size }}" [class.state-small]="isSmall" (click)="onGoToDetail()">
  <div class="inner">
    <div class="inner-content">
      <div class="date-con">
        <div class="date text-14">{{ data?.startDate | date: 'd MMMM' }} - {{ data?.endDate | date: 'd MMMM' }}</div>
      </div>

      <div class="title text-18 text-semibold">{{ peTypeNames[data.size] }}</div>
      <div class="description text-12">
        <div class="item">{{ duration }}</div>
        <div class="item">
          {{ durationType }} &nbsp; <span>{{ data?.feedDaysCount }} {{ data?.feedDaysCount | pluralize: 'days' }}</span>
        </div>
      </div>
      <div class="footer">
        <ng-container *ngIf="data.status !== SubscriptionStatusEnum.Unpaid; else unpaid">
          <ng-container *ngIf="data?.personalSchedule; else days">
            <div class="day-custom text-12 text-semibold">Свое расписание</div>
          </ng-container>

          <ng-template #days>
            <div class="day-item text-12 text-semibold" *ngFor="let item of deliveryDays">{{ item }}</div>
          </ng-template>
        </ng-container>

        <ng-template #unpaid>
          <div class="badge-unpaid text-12 text-semibold">Не оплачено</div>
        </ng-template>

        <div class="time text-12 text-semibold">{{ deliveryTime }}</div>
      </div>
    </div>
  </div>

  <ion-ripple-effect></ion-ripple-effect>
</div>
