<app-modal-layout (onGoBack)="closeModal()" (onClose)="closeModal()" [title]="'Выберите порцию'">
  <div class="wrapper">
    <h4 class="modal-dish-title text-16 text-semibold">{{dish?.title}}</h4>
    <p class="modal-dish-mealType text-14 text-semibold">{{ ruDishTypeEnum[mealType] }}</p>
    <div *ngFor="let id of dish?.id; let i = index" class="modal-dish-portion">
      <app-radio-toggler class="save-card-toggle" [value]="selectedDishIdOnLoad === id" (changeValue)="storeSelectedPortion(id)"
        >{{ dish.grams[i][id] }} г / {{ dish.caloricity[i][id] }} ккал</app-radio-toggler
      >
      <span *ngIf="dish?.priceIncrease && dish?.priceIncrease[i][id] !== 0" class="modal-dish-price-increase text-14 text-semibold"
        >+ {{dish?.priceIncrease[i][id]}} <span class="ruble">₽</span></span
      >
      <span *ngIf="dish?.additionalPrice && dish?.additionalPrice[i][id] !== 0" class="modal-dish-price-increase text-14 text-semibold"
        >+ {{dish?.additionalPrice[i][id]}} <span class="ruble">₽</span></span
      >
    </div>
    <button type="button" (click)="selectDishPortion()" class="btn btn-base modal-btn text-16 text-semibold">Выбрать</button>
  </div>
</app-modal-layout>
