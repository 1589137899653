import { ExtOptions } from '@shared/models';

/** Метод меняет первую букву на заглавную */
export function formatedString(menuType: string): string {
  if (!menuType) {
    return '';
  }

  return menuType.charAt(0).toUpperCase() + menuType.slice(1);
}

function getEatOptions(daysCount: number): Partial<ExtOptions> {
  const eatOnSaturday = daysCount % 6 === 0;
  const eatOnSaturdayAndSunday = daysCount % 7 === 0;

  return !daysCount
    ? {
        eatOnSaturday: false,
        eatOnSaturdayAndSunday: false,
      }
    : {
        eatOnSaturday: eatOnSaturday,
        eatOnSaturdayAndSunday: eatOnSaturdayAndSunday,
      };
}

export function getExtendedOptions(daysCount: number): ExtOptions {
  return {
    ...getEatOptions(daysCount),
    autoRenew: false,
    noBreakfastAndDinner: false,
  } as ExtOptions;
}
