<ng-container
  *ngIf="{
  fullName: fullName$ | async,
  phone: phone$ | async,
  isNewClient: isNewClient$ | async,
  profile: profile$ | async,
  address: address$ | async,
  deliveryTimeFrom: deliveryTimeFrom$ | async,
  deliveryTimeTo: deliveryTimeTo$ | async
} as data"
>
  <ng-container *ngIf="isDev">
    <div class="btn-admin" id="open-modal" expand="block"></div>

    <ion-modal trigger="open-modal">
      <ng-template>
        <ion-header mode="ios">
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button (click)="close()">Закрыть</ion-button>
            </ion-buttons>
            <ion-title>Admin panel: {{version }} </ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item>
            <button (click)="logout()" class="btn-base btn-small">Разлогиниться</button>

            <button (click)="showIntro()" class="btn-base btn-small">Показать Intro</button>
          </ion-item>
          <ion-item> <span class="text text-12">Имя Фамилия:</span> {{ data.fullName || '-' }} </ion-item>
          <ion-item> <span class="text text-12">Телефон:</span> {{ data.phone || '-' }} </ion-item>
          <ion-item> <span class="text text-12">email:</span> {{ data.profile?.email || '-' }} </ion-item>
          <ion-item> <span class="text text-12">Новый клиент?:</span> {{ data.isNewClient ? 'Да' : 'Нет' }} </ion-item>
          <ion-item> <span class="text text-12">Адрес:</span> {{ data.address }} </ion-item>
          <ion-item> <span class="text text-12">Время доставки:</span> {{ data.deliveryTimeFrom }} / {{ data.deliveryTimeTo }} </ion-item>
        </ion-content>
      </ng-template>
    </ion-modal>
  </ng-container>
</ng-container>
