import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FilterMethod } from '@shared/enums/filter-method.enum';

@Component({
  selector: 'app-dish-sorting-dropdown',
  templateUrl: './dish-sorting-dropdown.component.html',
  styleUrls: ['./dish-sorting-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishSortingDropdown {
  @Output() choosefilter = new EventEmitter<FilterMethod>();

  public filterMethod: FilterMethod = FilterMethod.ByCcal;
  public isSortingDropdownShown$ = new BehaviorSubject<boolean>(false);

  public readonly FilterMethod = FilterMethod;

  constructor(@Inject(DOCUMENT) private dom: Document) {}

  public showSortDropdown(): void {
    if (this.isSortingDropdownShown$.getValue()) {
      return;
    }

    this.isSortingDropdownShown$.next(true);
    setTimeout(() => {
      this.dom.addEventListener('click', this.hideDropdownHandler);
    }, 100);
  }

  public selectFilterMethod(filterMethod: FilterMethod) {
    this.filterMethod = filterMethod;
  }

  private hideDropdownHandler = event => {
    const elem = event.target;
    const isInsideModal = !!elem.closest('.header-dropdown-menu');

    if (!isInsideModal) {
      this.hideProfileDropDown();
    }
  };

  public hideProfileDropDown(): void {
    this.dom.removeEventListener('click', this.hideDropdownHandler);
    this.isSortingDropdownShown$.next(false);
  }

  public sortDishes(): void {
    this.choosefilter.emit(this.filterMethod);
    this.hideProfileDropDown();
  }
}
