<ng-container *ngIf="!isFeedbackSent; else thanks">
  <div class="text-20 text-semibold text-center">{{ feedback?.feedbackName }}</div>

  <div class="rating py-8">
    <app-rating [value]="rating" [size]="'large'" (changeValue)="chooseRating($event, feedback)" id="dish-feedback-rating"></app-rating>
  </div>

  <ng-container *ngIf="question; else closeBtn">
    <div class="text-center pb-4">
      <div class="text-16 text-semibold mb-4">{{ question }}</div>

      <div class="answers">
        <div
          *ngFor="let answer of answers"
          class="answer text-12"
          [class.choosen]="choosenAnswers.has(answer)"
          (click)="setChoosenAnswers(answer)"
        >
          {{ answer }}
        </div>
      </div>
    </div>

    <div class="comment">
      <textarea
        [placeholder]="rating <= 3 ? 'Расскажите что не понравилось. Проведём работу над ошибками' : 'Расскажите что мы можем сделать лучше. Мы будем работать над собой'"
        class="comment-textarea"
        [(ngModel)]="comment"
      ></textarea>
    </div>

    <button class="btn btn-base btn-send" (click)="onCompleteFeedback()">Оставить оценку</button>
  </ng-container>

  <ng-template #closeBtn>
    <button class="btn btn-base btn-send" (click)="closeModal()">Закрыть</button>
  </ng-template>
</ng-container>

<ng-template #thanks>
  <div class="text-20 text-semibold text-center">Спасибо за отзыв</div>
  <div class="text-14 text-center">Отправили его в отдел контроля качества. С вашей помощью мы становимся лучше</div>

  <img class="star" src="/assets/images/star-large.png" />

  <div class="banner text-16 text-semibold mb-6" (click)="showBanner()">
    <p class="text">
      Так же можете получить<br />
      <span>- 5%</span> за отзыв в соцсети
    </p>

    <p class="link">Подробнее</p>
  </div>

  <button class="btn btn-base btn-send" (click)="closeModal()">Продолжить</button>
</ng-template>
