import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { Device, DeviceInfo } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import dayjs from 'dayjs';

import { ClientMenuStateModel } from './client-menu.model';
import {
  SetActivePackage,
  LoadSubscriptions,
  LoadActiveSubscription,
  LoadReplacementDishes,
  ClearClientMenuData,
  UpdateDishFeedback,
  DeleteSubscription,
  LoadNotifications,
  MarkAsRead,
  CancelUnpaidSubscription,
  CompleteUnpaidSubscription,
  DeleteAllCustomizations,
  DeleteCustomizationById,
  StartCustomization,
  CancelUnpaidCustomizations,
} from './client-menu.actions';
import { ClientSubscriptionsApiService, PushService, RudderStackService, SubscriptionService, ToastService } from '@shared/services';
import { getExtendedOptions } from './client-menu.helpers';
import { ClientMenuDish, CustomizationProgressBody } from '@modules/client-menu/models';
import { ClientMenuApiService } from '@modules/client-menu/services';
import { CommonEvents, LoadingTagEnum, LocalStorageKeysEnum, SubscriptionStatusEnum } from '@shared/enums';
import { InitProgram, SetPromocode, UpdateProgramPrice } from '@store/program';
import { CurrentProgram, Notification } from '@shared/models';
import { deepClone, formatToDuration } from '@shared/utils';
import { ClientMenuEventsEnum } from '@modules/client-menu/enums';
import { LoadPaymentDetails, SetPaymentInfo, SetStartedPayment } from '@store/payment';
import { SetLoading, UnsetLoading } from '@store/loading';
import { AuthSelectors } from '@store/auth';

const initialState: ClientMenuStateModel = {
  activeSubscriptionDetails: null,
  subscriptionList: [],
  activePackage: null,
  replacementDishes: [],
  notifications: [],
};

@State<ClientMenuStateModel>({
  name: 'clientMenu',
  defaults: initialState,
})
@Injectable()
export class ClientMenuState {
  constructor(
    private router: Router,
    private store: Store,
    private apiService: ClientSubscriptionsApiService,
    private clientMenuService: ClientMenuApiService,
    private subscriptionService: SubscriptionService,
    private rudderstack: RudderStackService,
    private pushService: PushService,
    private toastService: ToastService,
  ) {}

  @Action(LoadActiveSubscription)
  loadActiveSubscription(
    { patchState, getState, dispatch }: StateContext<ClientMenuStateModel>,
    { subscriptionId, options }: LoadActiveSubscription,
  ) {
    let id = subscriptionId;

    if (!id) {
      const { subscriptionList } = getState();
      id = subscriptionList?.[0]?.subscriptionId;
    }

    const { activePackage } = getState();

    if (options?.date && activePackage && !options?.force) {
      dispatch(new SetActivePackage(options?.date));

      return;
    }

    dispatch(new SetLoading(LoadingTagEnum.isSubscriptionDetailsLoading));

    patchState({
      activeSubscriptionDetails: null,
      activePackage: null,
    });

    return this.apiService.getSubscriptionDetails(id).pipe(
      delay(100),
      tap(activeSubscriptionDetails => {
        dispatch(new UnsetLoading(LoadingTagEnum.isSubscriptionDetailsLoading));
        patchState({ activeSubscriptionDetails });

        const programParams: Partial<CurrentProgram> = {
          dailyCalories: activeSubscriptionDetails.size,
          durationId: formatToDuration(activeSubscriptionDetails.feedDaysCount),
          extOptions: getExtendedOptions(activeSubscriptionDetails.feedDaysCount),
        };

        dispatch([new SetActivePackage(options?.date), new InitProgram(programParams)]);

        if (options?.promocode) {
          dispatch([
            new SetPromocode(options.promocode, 'fromUrl'),
            new SetPromocode(options.promocode, 'fromInput'),
            new UpdateProgramPrice(options.promocode),
          ]);
        }
      }),
    );
  }

  @Action(StartCustomization)
  startCustomization(
    { getState, dispatch }: StateContext<ClientMenuStateModel>,
    { packageId, originalDishCode, newDishCode }: StartCustomization,
  ): Observable<any> {
    const body: CustomizationProgressBody = {
      originalDishCode,
      newDishCode,
    };

    return this.clientMenuService.customizationProgress(packageId, body).pipe(
      tap(() => {
        const { activeSubscriptionDetails, activePackage } = getState();
        const { subscriptionId } = activeSubscriptionDetails;
        const date = activePackage.date.slice(0, 10).split('-').reverse().join('-');

        dispatch(new LoadActiveSubscription(subscriptionId, { date, force: true }));
      }),
    );
  }

  @Action(DeleteCustomizationById)
  deleteCustomizationById(
    { getState, dispatch }: StateContext<ClientMenuStateModel>,
    { packageId, customizationProgressItemId }: DeleteCustomizationById,
  ): Observable<void> {
    return this.clientMenuService.cancelCustomizationProgress(packageId, customizationProgressItemId).pipe(
      delay(300),
      tap(() => {
        const { activeSubscriptionDetails, activePackage } = getState();
        const { subscriptionId } = activeSubscriptionDetails;
        const date = activePackage.date.slice(0, 10).split('-').reverse().join('-');

        dispatch(new LoadActiveSubscription(subscriptionId, { date, force: true }));
      }),
    );
  }

  @Action(DeleteAllCustomizations)
  deleteAllCustomizations(
    { getState, dispatch }: StateContext<ClientMenuStateModel>,
    { subscriptionId }: DeleteAllCustomizations,
  ): Observable<void> {
    return this.clientMenuService.cancelAllCustomizationProgress(subscriptionId).pipe(
      tap(() => {
        const { activePackage } = getState();
        const date = activePackage.date.slice(0, 10).split('-').reverse().join('-');

        dispatch(new LoadActiveSubscription(subscriptionId, { date, force: true }));
      }),
    );
  }

  @Action(CancelUnpaidCustomizations)
  cancelUnpaidCustomizations({ getState, dispatch }: StateContext<ClientMenuStateModel>): Observable<any> {
    const { activeSubscriptionDetails } = getState();
    const { subscriptionId, unfinishedPaymentId } = activeSubscriptionDetails;

    return this.clientMenuService.cancelCustomization(unfinishedPaymentId).pipe(
      delay(500),
      tap(() => dispatch(new LoadActiveSubscription(subscriptionId))),
    );
  }

  @Action(SetActivePackage)
  setActivePackage({ getState, patchState }: StateContext<ClientMenuStateModel>, { date }: SetActivePackage): void {
    const { activeSubscriptionDetails } = getState();
    const currentDate = date ? this.convertDate(date) : new Date();
    const activePackage = activeSubscriptionDetails.packageItems.find(item => {
      return dayjs(currentDate).isBefore(dayjs(item.date), 'day') || dayjs(currentDate).isSame(dayjs(item.date), 'day');
    });

    patchState({ activePackage });
  }

  /**
   *
   * @param activeDate дата формата 22-03-2023 2023-03-22
   * @returns дата формата 2023-03-22
   */
  private convertDate(activeDate: string): string {
    return activeDate.split('-').reverse().join('-');
  }

  @Action(LoadReplacementDishes)
  loadReplacementDishes(
    { patchState }: StateContext<ClientMenuStateModel>,
    { packageId, dishId }: LoadReplacementDishes,
  ): Observable<ClientMenuDish[]> {
    if (!packageId && !dishId) {
      patchState({
        replacementDishes: [],
      });

      return;
    }

    return this.clientMenuService.getReplacementDishes(packageId, dishId).pipe(tap(replacementDishes => patchState({ replacementDishes })));
  }

  @Action(LoadSubscriptions)
  loadSubscriptions({ patchState, dispatch }: StateContext<ClientMenuStateModel>) {
    dispatch(new SetLoading(LoadingTagEnum.isSubscriptionListLoading));

    return this.apiService.getAll().pipe(
      tap(list => {
        const activeList = list.filter(({ status }) => status !== SubscriptionStatusEnum.Past);

        patchState({ subscriptionList: activeList });

        if (!activeList.length) {
          this.rudderstack.track(ClientMenuEventsEnum.noSubscriptions);

          const urlAfterAuth = this.store.selectSnapshot(AuthSelectors.urlAfterAuth);

          if (Boolean(urlAfterAuth)) {
            this.router.navigate(['cabinet', 'create']);
          }

          return;
        }

        dispatch(new UnsetLoading(LoadingTagEnum.isSubscriptionListLoading));

        this.rudderstack.track(CommonEvents.showSubscriptionsList);
      }),
    );
  }

  @Action(DeleteSubscription)
  deleteSubscription({ dispatch }: StateContext<ClientMenuStateModel>, { subscriptionId }: DeleteSubscription): Observable<void> {
    return this.subscriptionService.deleteSubscription(subscriptionId).pipe(
      tap(async () => {
        dispatch(new SetPaymentInfo(null));

        const deviceInfo: DeviceInfo = await Device?.getInfo();
        const platform = deviceInfo?.platform;

        if (platform === 'ios') {
          this.router.navigate(['cabinet', 'create', 'program']);
          window.location.reload();
        }
      }),
    );
  }

  @Action(CancelUnpaidSubscription)
  cancelUnpaidSubscription(
    { dispatch, patchState }: StateContext<ClientMenuStateModel>,
    { subscriptionId }: CancelUnpaidSubscription,
  ): Observable<void> {
    if (!subscriptionId) {
      return;
    }

    return this.subscriptionService.deleteSubscription(subscriptionId).pipe(
      delay(500),
      tap(() => {
        patchState({ activePackage: null, activeSubscriptionDetails: null });
        dispatch(new LoadSubscriptions());
        this.router.navigate(['cabinet', 'menu']);
        this.toastService.presentToastSuccess('bottom', 'Подписка успешно отменена!');
      }),
    );
  }

  @Action(CompleteUnpaidSubscription)
  completeUnpaidSubscription({ dispatch, patchState }: StateContext<ClientMenuStateModel>, { subscriptionId }: CancelUnpaidSubscription) {
    return this.apiService.getPaymentIdBySubscription(subscriptionId).pipe(
      tap(async ({ paymentId }) => {
        await Preferences.set({
          key: LocalStorageKeysEnum.createdSubscriptionGuid,
          value: subscriptionId,
        });

        dispatch([new LoadPaymentDetails(paymentId), new SetStartedPayment(paymentId)]);
        this.router.navigate(['cabinet', 'create', 'payment']);
      }),
    );
  }

  @Action(ClearClientMenuData)
  clearClientMenuData({ patchState }: StateContext<ClientMenuStateModel>) {
    patchState(initialState);
  }

  @Action(UpdateDishFeedback)
  updateDishFeedback({ getState, patchState }: StateContext<ClientMenuStateModel>, { dish, feedback }: UpdateDishFeedback) {
    const { activeSubscriptionDetails, activePackage } = getState();
    const changedSubscription = deepClone(activeSubscriptionDetails);
    const currentPackage = deepClone(activePackage);
    const packageToUpdate = changedSubscription.packageItems.map(packageItem =>
      packageItem.packageId === currentPackage.packageId ? currentPackage : packageItem,
    );

    currentPackage.menuItems = currentPackage.menuItems.map(item => {
      if (item.id === dish.id) {
        return {
          ...item,
          dishFeedback: feedback,
        };
      }
      return item;
    });
    changedSubscription.packageItems = packageToUpdate;

    patchState({
      activeSubscriptionDetails: changedSubscription,
      activePackage: currentPackage,
    });
  }

  @Action(LoadNotifications)
  loadNotifications({ patchState, dispatch }: StateContext<ClientMenuStateModel>) {
    dispatch(new SetLoading(LoadingTagEnum.loadNotifications));

    return this.pushService.getNotifications().pipe(
      tap((notifications: Notification[]) => {
        patchState({ notifications });

        dispatch(new UnsetLoading(LoadingTagEnum.loadNotifications));
      }),
    );
  }

  @Action(MarkAsRead)
  markAsRead(ctx: StateContext<ClientMenuStateModel>, action: MarkAsRead) {
    return this.pushService.markAsRead(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const updatedNotifications = state.notifications.map(notification =>
          notification.id === action.id ? { ...notification, read: true } : notification,
        );
        ctx.patchState({ notifications: updatedNotifications });
      }),
    );
  }
}
