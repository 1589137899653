<div class="modal-placeholder" (click)="onClose.emit()"></div>

<div class="modal-container">
  <div class="delivery-message {{ alertClass }}" *ngIf="alertText">{{ alertText }}</div>
  <div class="modal-container-inner">
    <div class="container">
      <div class="header text-20 text-semibold">
        <div class="title">{{ title }}</div>

        <div class="btn-back" *ngIf="showBack" (click)="onGoBack.emit()">
          <app-icon icon="modal-back" class="icon-back"></app-icon>
        </div>
      </div>

      <ng-content></ng-content>
    </div>
  </div>
</div>
