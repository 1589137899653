import { ClientMenuDish } from '@modules/client-menu/models';
import { ClientSubscriptionPackage } from '@shared/models';
import { PackageStateEnum, SubscriptionStatusEnum } from '@shared/enums';

export class DishHelper {
  // Tooltip text logic moved to helper
  public static getTooltipText(
    dish: ClientMenuDish,
    pkg: ClientSubscriptionPackage,
    isFromDetailsModal: boolean,
    isFromAdditionModal: boolean,
  ): string {
    if (new Date(pkg?.date).getDay() === 0) {
      return this.createTooltipText('onSunday');
    }
    if (!dish.isReplaceable && !(isFromDetailsModal || isFromAdditionModal)) {
      return this.createTooltipText('notReplaceable');
    }
    if (dish?.isReplaced || pkg?.hasConfirmedCustomization) {
      return this.createTooltipText('replaced');
    }
    if (!dish?.isReplaceable && pkg?.isCustomizable) {
      return this.createTooltipText('notReplaceable');
    }
    return pkg?.isCustomizable ? this.createTooltipText('replaced') : this.createTooltipText('sent');
  }

  private static createTooltipText(type: string): string {
    const tooltipTexts = {
      sent: 'Замена недоступна, т.к. меню этого дня мы уже отправили на готовку',
      replaced: 'Замена недоступна, т.к. вы уже подтвердили замену на этот день',
      notReplaceable: 'Для данного блюда функция замены недоступна',
      onSunday: 'Замена блюда недоступна, уже работаем над тем, чтобы дать вам больше возможностей',
    };
    return `<div class="tooltip"><p>${tooltipTexts[type]}</p></div>`;
  }

  // Move isTooltipDisabled logic to helper
  public static isTooltipDisabled(dish: ClientMenuDish, pkg: ClientSubscriptionPackage): boolean {
    return dish?.shouldBeConfirmed || !(pkg?.hasConfirmedCustomization || !pkg?.isCustomizable);
  }

  // Button visibility and logic moved to helper
  public static shouldShowButton(dish: ClientMenuDish, pkg: ClientSubscriptionPackage): boolean {
    return !dish?.hasDuplicateDish || dish?.shouldBeConfirmed || dish?.hasDuplicateDish || pkg?.isCustomizable;
  }

  // Define button types for easier handling
  public static getButtonType(
    dish: ClientMenuDish,
    pkg: ClientSubscriptionPackage,
    isFromDetailsModal: boolean,
    isFromAdditionModal: boolean,
  ): string {
    if (this.shouldShowReplaceButton(dish, pkg, isFromDetailsModal)) return 'replace';
    if (this.shouldShowReplaceFromDetailsButton(dish, pkg, isFromDetailsModal, isFromAdditionModal)) return 'replaceFromDetails';
    if (this.shouldShowAddDishButton(dish, pkg, isFromAdditionModal, isFromDetailsModal)) return 'addDish';
    if (this.shouldShowSelectPortionButton(dish, isFromDetailsModal)) return 'selectPortion';
    if (this.shouldShowUndoChangeButton(dish, pkg)) return 'undoChange';
    if (this.shouldShowRateDishButton(dish, pkg, isFromDetailsModal)) return 'rateDish';
    if (this.shouldShowDeselectButton(dish, pkg)) return 'deselect';
    return 'default';
  }

  public static getButtonText(
    dish: ClientMenuDish,
    pkg: ClientSubscriptionPackage,
    isFromDetailsModal: boolean,
    isFromAdditionModal: boolean,
  ): string {
    const buttonType = DishHelper.getButtonType(dish, pkg, isFromDetailsModal, isFromAdditionModal);

    switch (buttonType) {
      case 'replace':
        return 'Заменить';
      case 'replaceFromDetails':
        return 'Заменить блюдо';
      case 'addDish':
        return 'Добавить блюдо';
      case 'selectPortion':
        return 'Выбрать порцию';
      case 'undoChange':
        return 'Отменить замену';
      case 'rateDish':
        return 'Оценить';
      case 'deselect':
        return 'Отменить';
      default:
        return '';
    }
  }

  public static getButtonClass(isFromDetailsModal: boolean): string {
    return isFromDetailsModal ? 'black-button' : '';
  }

  public static isButtonDisabled(
    dish: ClientMenuDish,
    pkg: ClientSubscriptionPackage,
    isFromDetailsModal: boolean,
    isFromAdditionModal: boolean,
    subscriptionStatus: SubscriptionStatusEnum,
  ): boolean {
    if (subscriptionStatus !== SubscriptionStatusEnum.Active) {
      return true;
    }
    if (!dish.isReplaceable && !isFromAdditionModal) {
      return true;
    }
    if (
      this.shouldShowAddDishButton(dish, pkg, isFromAdditionModal, isFromDetailsModal) ||
      this.shouldShowReplaceFromDetailsButton(dish, pkg, isFromDetailsModal, isFromAdditionModal)
    ) {
      return pkg?.hasConfirmedCustomization || !pkg?.isCustomizable;
    }
    if (this.shouldShowReplaceButton(dish, pkg, isFromDetailsModal)) {
      return (
        pkg?.hasConfirmedCustomization || !pkg?.isCustomizable || !(dish.isReplaceable && !(isFromDetailsModal || isFromAdditionModal))
      );
    }
    if (this.shouldShowDeselectButton(dish, pkg)) {
      return !pkg?.isCustomizable;
    }
    return false;
  }

  // Extract conditions to helper
  private static shouldShowReplaceButton(dish: ClientMenuDish, pkg: ClientSubscriptionPackage, isFromDetailsModal: boolean): boolean {
    return (
      !dish?.hasDuplicateDish &&
      !dish?.shouldBeConfirmed &&
      !(dish?.isReplaced && !pkg.hasConfirmedCustomization) &&
      pkg.packageState !== PackageStateEnum.delivered &&
      !isFromDetailsModal
    );
  }

  private static shouldShowReplaceFromDetailsButton(
    dish: ClientMenuDish,
    pkg: ClientSubscriptionPackage,
    isFromDetailsModal: boolean,
    isFromAdditionModal: boolean,
  ): boolean {
    return (
      !dish?.hasDuplicateDish &&
      !dish?.shouldBeConfirmed &&
      !(dish?.isReplaced && !pkg.hasConfirmedCustomization) &&
      !isFromAdditionModal &&
      isFromDetailsModal
    );
  }

  private static shouldShowAddDishButton(
    dish: ClientMenuDish,
    pkg: ClientSubscriptionPackage,
    isFromAdditionModal: boolean,
    isFromDetailsModal: boolean,
  ): boolean {
    return (
      !dish?.hasDuplicateDish &&
      !dish?.shouldBeConfirmed &&
      !(dish?.isReplaced && !pkg.hasConfirmedCustomization) &&
      isFromAdditionModal &&
      isFromDetailsModal
    );
  }

  private static shouldShowSelectPortionButton(dish: ClientMenuDish, isFromDetailsModal: boolean): boolean {
    return isFromDetailsModal && dish?.hasDuplicateDish;
  }

  private static shouldShowUndoChangeButton(dish: ClientMenuDish, pkg: ClientSubscriptionPackage): boolean {
    return pkg?.isCustomizable && dish?.shouldBeConfirmed && !dish?.isAdded && !(dish?.isReplaced && !pkg.hasConfirmedCustomization);
  }

  private static shouldShowRateDishButton(dish: ClientMenuDish, pkg: ClientSubscriptionPackage, isFromDetailsModal: boolean): boolean {
    return pkg?.packageState === 'Delivered' && !isFromDetailsModal && (dish.dishFeedback?.rating === 0 || dish.dishFeedback === null);
  }

  private static shouldShowDeselectButton(dish: ClientMenuDish, pkg: ClientSubscriptionPackage): boolean {
    return pkg?.isCustomizable && dish?.shouldBeConfirmed && !dish?.isReplaced && dish?.isAdded;
  }
}
