import { ChangeDetectionStrategy, Input, Component, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { DeleteCustomizationById } from '@store/client-menu';
import { NgOnDestroyService, RudderStackService } from '@shared/services';
import { RuDishTypesEnum, ImageTypeEnum, ImageFileTypeEnum, ProgramTypesEnum, SubscriptionStatusEnum } from '@shared/enums';
import { DishImagePipe } from '@shared/pipes';
import { ClientSubscriptionPackage } from '@shared/models';
import { ClientMenuDish } from '@modules/client-menu/models';
import { FeedbackDishService } from '@modules/client-menu/services';
import { ClientMenuEventsEnum } from '@modules/client-menu/enums';
import { DishDetailsComponent } from '../dish-details';

@Component({
  selector: 'app-dish-item',
  templateUrl: './dish-item.component.html',
  styleUrls: ['./dish-item.component.scss'],
  providers: [NgOnDestroyService, DishImagePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishItemComponent {
  @Input() dish: ClientMenuDish;
  @Input() programType: ProgramTypesEnum;
  @Input() scrollableContainer: HTMLElement;
  @Input() package: ClientSubscriptionPackage;
  @Input() subscriptionStatus = SubscriptionStatusEnum.Active;

  readonly ruDishTypeEnum = RuDishTypesEnum;
  readonly ImageTypeEnum = ImageTypeEnum;
  readonly ImageFileTypeEnum = ImageFileTypeEnum;

  constructor(
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private dishImagePipe: DishImagePipe,
    private store: Store,
    private modalCtrl: ModalController,
    private feedbackDishService: FeedbackDishService,
    private rudderstack: RudderStackService,
  ) {}

  getImageSources(dishCode: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    if (!dishCode) {
      return null;
    }

    return this.dishImagePipe.transform(dishCode, imageType, fileType);
  }

  deselect(dish: ClientMenuDish): void {
    this.store.dispatch(new DeleteCustomizationById(this.package.packageId, dish.customizationId));
  }

  public setDishRating(dish: ClientMenuDish, userPackage: ClientSubscriptionPackage): void {
    this.feedbackDishService
      .openDialog({
        dish,
        packageId: userPackage.packageId,
        date: userPackage.date,
      })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe();
  }

  async open(dish: ClientMenuDish): Promise<void> {
    this.trackIngredientsInterest(dish.id, dish.title);

    const modal = await this.modalCtrl.create({
      component: DishDetailsComponent,
      cssClass: 'dish-details-modal',
      mode: 'md',
      breakpoints: [0, 0.25, 0.5, 1],
      initialBreakpoint: 1,
      componentProps: {
        dish,
        package: this.package,
      },
    });

    modal.present();
  }

  private trackIngredientsInterest(id: string, title: string): void {
    this.rudderstack.track(ClientMenuEventsEnum.ingredientsInterest, { dishId: id, dishTitle: title });
  }
}
